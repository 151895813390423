import React, {Component} from 'react';

class ParentGroupList extends Component {
    render() {
        let parentGroups = this.props.parentGroups;
        let parentGroupList = [];

        if (this.props.selectedParentGroups) {
            let onGroupSelected = this.props.onParentGroupSelected || (() => {
            });
            parentGroupList = parentGroups.map((group) => {
                    let selected = this.props.selectedParentGroups.includes(group.id) ? <i className="float-end bi-check"></i> : <i></i>
                    return <li key={group.id} className="list-group-item list-group-item-action" onClick={(event) => {
                        event.stopPropagation();
                        onGroupSelected(group)
                    }}>{group.name}{selected}</li>
                }
            );
        }

        return <ul className="list-group shadow-sm mb-5 bg-body rounded">
            {parentGroupList}
        </ul>
    }
}

export default ParentGroupList;
