import React, {Component} from 'react';
import frCHLocale from "date-fns/locale/fr-CH";
import {TextField} from "@mui/material";
import format from "date-fns/format";
import InputMask from "react-input-mask";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import add from "date-fns/add";

class EventDatePicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fromDate: props.fromDate,
            toDate: props.toDate,
            fromHour: format(new Date(props.fromDate), 'HH:mm'),
            toHour: format(new Date(props.toDate), 'HH:mm'),
            wholeDay: props.wholeDay
        };
    }

    onDateUpdated(fromDate, toDate, wholeDay, dayChanged) {
        var toDate = new Date(toDate);
        var fromDate = new Date(fromDate);
        let currentFromDate = new Date(this.state.fromDate);
        let currentToDate = new Date(this.state.toDate);

        let newFromDate = new Date(fromDate)
        let newToDate = new Date(toDate)

        // Prevent invalid dates
        if (isNaN(newFromDate.getTime())) {
            newFromDate = currentFromDate
        }
        if (isNaN(newToDate.getTime())) {
            newToDate = currentToDate
        }

        // Prevent changing the hours if we only changed the day
        if (dayChanged) {
            newFromDate.setHours(currentFromDate.getHours());
            newFromDate.setMinutes(currentFromDate.getMinutes());

            newToDate.setHours(currentToDate.getHours());
            newToDate.setMinutes(currentToDate.getMinutes());
        }

        if (fromDate >= toDate) {
            if (newFromDate.getTime() !== currentFromDate.getTime()) {
                newToDate = new Date(newFromDate)

                newToDate.setHours(toDate.getHours());
                newToDate.setMinutes(toDate.getMinutes());

                // Hours are still not right, add 1 hour
                if (newFromDate >= newToDate) {
                    newToDate = add(newFromDate, {hours: 1})
                }
            } else if (newToDate.getTime() !== currentToDate.getTime()) {
                newFromDate = new Date(newToDate)

                newFromDate.setHours(fromDate.getHours());
                newFromDate.setMinutes(fromDate.getMinutes());

                // Hours are still not right, subtract 1 hour
                if (newFromDate >= newToDate) {
                    newFromDate = add(newToDate, {hours: -1})
                }
            }
        }

        this.setState({
            fromDate: newFromDate,
            toDate: newToDate,
            fromHour: format(newFromDate, 'HH:mm'),
            toHour: format(newToDate, 'HH:mm'),
            wholeDay: wholeDay
        })

        if (this.props.onDateUpdated) {
            this.props.onDateUpdated(newFromDate, newToDate, wholeDay);
        }
    }

    render() {
        let fromHourTextfield = null;
        let toElements = null;
        if (!this.state.wholeDay) {
            fromHourTextfield = <InputMask
                className="form-control"
                mask="99:99"
                permanents={[2]}
                maskChar="0"
                value={this.state.fromHour}
                onBlur={(event) => {
                    let fromDate = new Date(this.state.fromDate);
                    fromDate.setHours(event.target.value.substring(0, 2));
                    fromDate.setMinutes(event.target.value.substring(3, 5));

                    this.onDateUpdated(fromDate, this.state.toDate, this.state.wholeDay, false);
                }}
                onChange={(event) => {
                    this.setState({...this.state, fromHour: event.target.value})
                }}
            />

            toElements = <React.Fragment>
                <span className="input-group-text">Au</span>
                <span className="form-control">
                <DatePicker
                    renderInput={(params) => <TextField variant="standard" {...params} />}
                    mask="__.__.____"
                    value={this.state.wholeDay ? null : this.state.toDate}
                    onChange={(date) => {
                        this.onDateUpdated(this.state.fromDate, date, this.state.wholeDay, true);
                    }}
                />
                </span>
                <InputMask
                    className="form-control"
                    mask="99:99"
                    permanents={[2]}
                    maskChar="0"
                    value={this.state.toHour}
                    onBlur={(event) => {
                        let toDate = new Date(this.state.toDate);
                        toDate.setHours(event.target.value.substring(0, 2));
                        toDate.setMinutes(event.target.value.substring(3, 5));

                        this.onDateUpdated(this.state.fromDate, toDate, this.state.wholeDay, false);
                    }}
                    onChange={(event) => {
                        this.setState({...this.state, toHour: event.target.value})
                    }}
                />
            </React.Fragment>
        }

        return <li className="list-group-item">
            <div className="mb-2 mt-2">
                <label className="form-check-label me-2" htmlFor="wholeDay">Jour entier:</label>
                <input type="checkbox" className="form-check-input" id="wholeDay" name="wholeDay"
                       onChange={(event) => {
                           this.onDateUpdated(this.state.fromDate, this.state.toDate, event.target.checked, true)
                       }}
                       checked={this.state.wholeDay}/>
            </div>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frCHLocale}>
                <div className="input-group">
                    <span className="input-group-text">{this.state.wholeDay ? "Le" : "Du"}</span>
                    <span className="form-control">
                    <DatePicker
                        renderInput={(params) => <TextField variant="standard" {...params} />}
                        mask="__.__.____"
                        value={this.state.fromDate}
                        onChange={(date) => {
                            this.onDateUpdated(date, this.state.toDate, this.state.wholeDay, true);
                        }}
                    />
                    </span>
                    {fromHourTextfield}
                    {toElements}
                </div>
            </LocalizationProvider>
        </li>
    }
}

export default EventDatePicker;
