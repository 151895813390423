import React, {Component} from 'react';
import APIFetcher from "../../API";
import {WithContext as ReactTags} from 'react-tag-input';
import './style/tags.css'
import add from 'date-fns/add'
import OrderedGroupList from "../groups/OrderedGroupList";
import EventDatePicker from "./EventDatePicker";

class NewEventPage extends Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDeletePlace = this.handleDeletePlace.bind(this);
        this.handleAddPlace = this.handleAddPlace.bind(this);
        this.didSelectGroup = this.didSelectGroup.bind(this);
        this.handleDateUpdated = this.handleDateUpdated.bind(this);
        this.handleSelectAllGroups = this.handleSelectAllGroups.bind(this);

        let now = new Date();
        now.setHours(8, 0);
        this.fromDate = now;
        this.toDate = add(now, {hours: 1});
        this.wholeDay = false;
        this.state = {fromDate: now, toDate: add(now, {hours: 1}), groupsHidden: true, loading: true, groups: [], selectedGroups: [], places: [], addedPlaces: [], error: null};
    }

    componentDidMount() {
        APIFetcher.getParentGroups(true)
            .then(response => {
                this.setState({...this.state, groupsHidden: true, loading: false, groups: response.data, selectedGroups: [], error: null})
            })
            .catch(error => {
                this.setState({...this.state, groupsHidden: true, loading: false, groups: [], selectedGroups: [], error: error})
            })

        APIFetcher.getPlaces()
            .then(response => {
                let tagPlaces = response.data.map((place) => {
                    return {id: "" + place.id, text: place.name}
                })
                this.setState({...this.state, places: tagPlaces})
            })
            .catch(error => {
            })
    }

    handleDeletePlace(placeIndex) {
        let addedPlaces = this.state.addedPlaces;
        this.setState({...this.state, addedPlaces: addedPlaces.filter((tag, index) => index !== placeIndex)})
    }

    handleAddPlace(place) {
        let addedPlaces = this.state.addedPlaces;
        addedPlaces.push(place);
        this.setState({...this.state, addedPlaces: addedPlaces})
    }

    handleDateUpdated(fromDate, toDate, wholeDay) {
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.wholeDay = wholeDay;
    }

    handleSubmit(e) {
        let placeNames = this.state.addedPlaces.map((place) => {
            return place.text
        })
        e.preventDefault();

        let form = e.target;
        APIFetcher.createEvent(
            form.eventTitle.value,
            form.eventDescription.value,
            this.fromDate / 1000,
            this.toDate / 1000,
            this.wholeDay,
            this.state.selectedGroups,
            placeNames,
            this.notify)
            .then(response => {
                this.props.history.push("/")
            })
            .catch(error => {
                alert(error);
            })
    }

    didSelectGroup(group) {
        let selectedGroups = this.state.selectedGroups;
        if (selectedGroups.includes(group.id)) {
            selectedGroups = selectedGroups.filter(groupId => groupId !== group.id);
        } else {
            selectedGroups.push(group.id);
        }
        this.setState({...this.state, selectedGroups: selectedGroups})
    }

    handleSelectAllGroups(event) {
        event.stopPropagation();
        let allGroups = [];
        this.state.groups.forEach(parentGroup => {
            parentGroup.groups.forEach(group => {
                if (!allGroups.includes(group.id)) {
                    allGroups.push(group.id)
                }
            })
        });
        this.setState({...this.state, groupsHidden: false, selectedGroups: allGroups})
    }

    render() {
        let groupList;
        if (this.state.loading) {
            groupList = <p>loading</p>
        } else if (this.state.error) {
            groupList = <p>error loading group list</p>
        } else {
            groupList = <OrderedGroupList parentGroups={this.state.groups} selectedGroups={this.state.selectedGroups} onGroupSelected={this.didSelectGroup}/>
        }

        return <div className="container-sm">
            <div className="p-3">
                <p className="text-center h2">Nouvel événement</p>
                <form className="shadow-sm mb-5 bg-body rounded" onSubmit={this.handleSubmit}>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <label htmlFor="eventTitle" className="form-label">Titre:</label>
                            <input type="text" id="eventTitle" name="eventTitle" className="form-control"/>
                        </li>
                        <li className="list-group-item">
                            <label className="form-label" htmlFor="eventDescription">Description:</label>
                            <textarea id="eventDescription" name="eventDescription" className="form-control"/>
                        </li>
                        <EventDatePicker fromDate={this.fromDate} toDate={this.toDate} wholeDay={this.wholeDay} onDateUpdated={this.handleDateUpdated}/>
                        <li className="list-group-item">
                            <div className="mb-3" onClick={() => this.setState({...this.state, groupsHidden: !this.state.groupsHidden})}>
                                Groupes
                                <div className="float-end">
                                    <button type="button" className="btn btn-outline-primary btn-sm me-4" onClick={this.handleSelectAllGroups}>Tout sélectionner</button>
                                    <i className={"bi bi-chevron-" + (this.state.groupsHidden ? "right" : "down")}></i>
                                </div>
                            </div>
                            {this.state.groupsHidden ? <span></span> : groupList}
                        </li>
                        <li className="list-group-item">
                            <div>Lieux</div>
                            <ReactTags placeholder={"Nom du lieu"}
                                       tags={this.state.addedPlaces}
                                       suggestions={this.state.places}
                                       allowDragDrop={false}
                                       handleDelete={this.handleDeletePlace}
                                       handleAddition={this.handleAddPlace}/>
                        </li>
                        <li className="list-group-item text-center">
                            <div className="float-end">
                                <button type="submit" onClick={() => {
                                    this.notify = false
                                }} className="btn btn-primary me-2">Publier
                                </button>
                                <button type="submit" onClick={() => {
                                    this.notify = true
                                }} className="btn btn-primary"><i className="bi bi-megaphone-fill me-2"></i>Publier et notifier
                                </button>
                            </div>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    }
}

export default NewEventPage;
