import React, {Component} from 'react';

class DeleteModal extends Component {
    render() {
        let notify = this.props.notify;
        let confirmCallback = this.props.confirmCallback;
        let id = notify ? "deleteNotifyModal" : "deleteModal";
        let deleteButton;
        if (notify) {
            deleteButton =
                <button onClick={confirmCallback} type="button" className="btn btn-danger me-2" data-bs-dismiss="modal"><i className="bi bi-megaphone-fill me-2"></i>Supprimer et notifier</button>
        } else {
            deleteButton = <button onClick={confirmCallback} type="button" className="btn btn-danger me-2" data-bs-dismiss="modal"><i className="bi bi-trash-fill me-2"></i>Supprimer</button>
        }
        return <div className="modal fade" id={id} tabIndex="-1" aria-labelledby={id} aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Confirmer la suppression</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        Êtes-vous sûr de vouloir supprimer l'élément ?
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Annuler</button>
                        {deleteButton}
                    </div>
                </div>
            </div>
        </div>
    }
}

export default DeleteModal;
