import React, {Component} from 'react';
import APIFetcher from "../../../API";

class NewParentGroupPage extends Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        let form = e.target;
        APIFetcher.createParentGroup(form.groupName.value)
            .then(response => {
                this.props.history.push("/parent_groups")
            })
            .catch(error => {
                alert(error);
            })
    }

    render() {
        return <div className="container-sm">
            <div className="p-3">
                <p className="text-center h2">Nouveau groupe parent</p>
                <form className="shadow-sm mb-5 bg-body rounded" onSubmit={this.handleSubmit}>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <label htmlFor="eventTitle" className="form-label">Nom:</label>
                            <input type="text" id="eventTitle" name="groupName" className="form-control"/>
                        </li>
                        <li className="list-group-item text-center">
                            <button type="submit" className="btn btn-primary float-end">Créer</button>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    }
}

export default NewParentGroupPage;
