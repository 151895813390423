import React, {Component} from 'react';
import APIFetcher from "../../API";
import ParentGroupList from "./ParentGroupList";

class NewUserPage extends Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.didSelectParentGroup = this.didSelectParentGroup.bind(this);
        this.state = {parentGroupsHidden: true, loading: true, parentGroups: [], selectedParentGroups: [], error: null};
    }

    componentDidMount() {
        APIFetcher.getParentGroups()
            .then(response => {
                this.setState({...this.state, loading: false, parentGroups: response.data, error: null})
            })
            .catch(error => {
                this.setState({...this.state, parentGroupsHidden: true, loading: false, parentGroups: [], error: error})
            })
    }

    handleSubmit(e) {
        e.preventDefault();
        let form = e.target;
        APIFetcher.createUser(form.email.value, form.lastname.value, form.backofficeAccess.checked, this.state.selectedParentGroups)
            .then(response => {
                this.props.history.push("/users")
            })
            .catch(error => {
                alert(error)
            })
    }

    didSelectParentGroup(group) {
        let selectedParentGroups = this.state.selectedParentGroups;
        if (selectedParentGroups.includes(group.id)) {
            selectedParentGroups = selectedParentGroups.filter(groupId => groupId !== group.id);
        } else {
            selectedParentGroups.push(group.id);
        }
        this.setState({...this.state, selectedParentGroups: selectedParentGroups})
    }

    render() {
        let parentGroupList;
        if (this.state.loading) {
            parentGroupList = <p>loading</p>
        } else if (this.state.error) {
            parentGroupList = <p>error loading group list</p>
        } else {
            parentGroupList = <ParentGroupList parentGroups={this.state.parentGroups} selectedParentGroups={this.state.selectedParentGroups} onParentGroupSelected={this.didSelectParentGroup}/>
        }

        return <div className="container-sm">
            <div className="p-3">
                <p className="text-center h2">Nouvel utilisateur</p>
                <form className="shadow-sm mb-5 bg-body rounded" onSubmit={this.handleSubmit}>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <label htmlFor="email" className="form-label">Email:</label>
                            <input type="email" id="email" name="email" className="form-control"/>
                        </li>
                        <li className="list-group-item">
                            <label htmlFor="lastname" className="form-label">Nom:</label>
                            <input type="text" id="lastname" name="lastname" className="form-control"/>
                        </li>
                        <li className="list-group-item">
                            <label className="form-check-label me-2" htmlFor="backofficeAccess">
                                Accès backoffice:
                            </label>
                            <input className="form-check-input" type="checkbox" value="" name="backofficeAccess" id="backofficeAccess"/>
                        </li>
                        <li className="list-group-item">
                            <div className="" onClick={() => this.setState({...this.state, parentGroupsHidden: !this.state.parentGroupsHidden})}>
                                Groupes parents autorisés <i className={"float-end bi bi-chevron-" + (this.state.parentGroupsHidden ? "right" : "down")}></i>
                            </div>
                            {this.state.parentGroupsHidden ? <span></span> : parentGroupList}
                        </li>
                        <li className="list-group-item text-center">
                            <button type="submit" className="btn btn-primary float-end">Créer</button>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    }
}

export default NewUserPage;
