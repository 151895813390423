import React, {Component} from 'react';
import APIFetcher from "../../API";
import DeleteModal from "../events/DeleteModal";
import ParentGroupList from "./ParentGroupList";
import GroupList from "../groups/GroupList";

class EditUserPage extends Component {

    constructor(props) {
        super(props);
        this.user = this.props.location.state;
        this.state = {parentGroupsHidden: true, addedGroupsHidden: true, parentGroups: [], selectedParentGroups: [], parentGroupsLoadingError: null, userLoadingError: null};

        this.handleDelete = this.handleDelete.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.didSelectParentGroup = this.didSelectParentGroup.bind(this);
    }

    componentDidMount() {
        APIFetcher.getParentGroups()
            .then(response => {
                this.setState({...this.state, parentGroupsHidden: true, addedGroupsHidden: true, parentGroups: response.data, parentGroupsLoadingError: null})
            })
            .catch(error => {
                this.setState({...this.state, parentGroupsHidden: true, addedGroupsHidden: true, parentGroups: null, parentGroupsLoadingError: error})
            })

        APIFetcher.getUser(this.user.id)
            .then(response => {
                let selectedParentGroups = response.data.available_parent_groups.map((group) => {
                    return group.id
                })

                this.setState({...this.state, addedGroupsHidden: true, user: response.data, selectedParentGroups: selectedParentGroups, userLoadingError: null})
            })
            .catch(error => {
                this.setState({...this.state, addedGroupsHidden: true, user: null, userLoadingError: error})
            })
    }

    didSelectParentGroup(group) {
        let selectedParentGroups = this.state.selectedParentGroups;
        if (selectedParentGroups.includes(group.id)) {
            selectedParentGroups = selectedParentGroups.filter(groupId => groupId !== group.id);
        } else {
            selectedParentGroups.push(group.id);
        }
        this.setState({...this.state, selectedParentGroups: selectedParentGroups})
    }

    handleSubmit(e) {
        e.preventDefault();
        let form = e.target;
        APIFetcher.updateUser(this.user.id, form.email.value, form.lastname.value, form.backofficeAccess.checked, this.state.selectedParentGroups)
            .then(response => {
                this.props.history.push("/users")
            })
            .catch(error => {
                alert(error)
            })
    }

    handleDelete() {
        APIFetcher.deleteUser(this.user.id)
            .then(response => {
                this.props.history.push("/users")
            })
            .catch(error => {
                alert(error);
            })
    }

    render() {
        let parentGroupList;
        if (this.state.parentGroupsLoadingError) {
            parentGroupList = <p>Erreur pendant le chargement</p>
        } else if (this.state.parentGroups) {
            parentGroupList = <ParentGroupList parentGroups={this.state.parentGroups} selectedParentGroups={this.state.selectedParentGroups} onParentGroupSelected={this.didSelectParentGroup}/>
        } else {
            parentGroupList = <p>Chargement...</p>
        }


        let userGroupList;
        if (this.state.userLoadingError) {
            userGroupList = <p>Erreur pendant le chargement</p>
        } else if (this.state.user) {
            userGroupList = <GroupList noLink groups={this.state.user.groups}/>
        } else {
            userGroupList = <p>Chargement...</p>
        }

        return <div className="container-sm">
            <DeleteModal confirmCallback={this.handleDelete}/>
            <div className="p-3">
                <p className="text-center h2">Modifier utilisateur</p>
                <form className="shadow-sm mb-5 bg-body rounded" onSubmit={this.handleSubmit}>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <label htmlFor="email" className="form-label">Email:</label>
                            <input type="text" id="email" name="email" className="form-control" defaultValue={this.user.email}/>
                        </li>
                        <li className="list-group-item">
                            <label htmlFor="lastname" className="form-label">Nom:</label>
                            <input type="text" id="lastname" name="lastname" className="form-control" defaultValue={this.user.lastname}/>
                        </li>
                        <li className="list-group-item">
                            <label className="form-check-label me-2" htmlFor="backofficeAccess">
                                Accès backoffice:
                            </label>
                            <input className="form-check-input" type="checkbox" value="" name="backofficeAccess" id="backofficeAccess" defaultChecked={this.user.backofficeAccess}/>
                        </li>
                        <li className="list-group-item">
                            <div className="" onClick={() => this.setState({...this.state, parentGroupsHidden: !this.state.parentGroupsHidden})}>
                                Groupes parents autorisés <i className={"float-end bi bi-chevron-" + (this.state.parentGroupsHidden ? "right" : "down")}></i>
                            </div>
                            {this.state.parentGroupsHidden ? <span></span> : parentGroupList}
                        </li>
                        <li className="list-group-item">
                            <div className="" onClick={() => this.setState({...this.state, addedGroupsHidden: !this.state.addedGroupsHidden})}>
                                Groupes ajoutés par l'utilisateur <i className={"float-end bi bi-chevron-" + (this.state.addedGroupsHidden ? "right" : "down")}></i>
                            </div>
                            {this.state.addedGroupsHidden ? <span></span> : userGroupList}
                        </li>
                        <li className="list-group-item text-center">
                            <div className="float-end">
                                <button type="button" className="btn btn-danger me-2" data-bs-toggle="modal" data-bs-target="#deleteModal"><i className="bi bi-trash-fill me-2"></i>Supprimer</button>
                                <button type="submit" className="btn btn-primary me-2">Modifier</button>
                            </div>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    }
}

export default EditUserPage;
