import React, {Component} from 'react';
import APIFetcher from "../../API";

class NewGroupPage extends Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        let form = e.target;
        APIFetcher.createGroup(form.groupName.value, form.groupPublic.checked)
            .then(response => {
                this.props.history.push("/groups")
            })
            .catch(error => {
                alert(error);
            })
    }

    render() {
        return <div className="container-sm">
            <div className="p-3">
                <p className="text-center h2">Nouveau groupe</p>
                <form className="shadow-sm mb-5 bg-body rounded" onSubmit={this.handleSubmit}>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <label htmlFor="groupName" className="form-label">Nom:</label>
                            <input type="text" id="groupName" name="groupName" className="form-control"/>
                        </li>
                        <li className="list-group-item">
                            <label className="form-check-label me-2" htmlFor="groupPublic">Groupe public</label>
                            <input type="checkbox" className="form-check-input" id="groupPublic" name="groupPublic"/>
                        </li>
                        <li className="list-group-item text-center">
                            <button type="submit" className="btn btn-primary float-end">Créer</button>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    }
}

export default NewGroupPage;
