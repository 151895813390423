import Axios from 'axios'

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Axios.defaults.headers.common['Accept'] = 'application/json';

const API = Axios.create({
    baseURL: 'https://api.cfparts.ch/',
    withCredentials: true,
})

class ApiError extends Error {
    constructor(message, status) {
        super(message);
        this.status = status;
    }
}

const APIFetcher = {
    disconnect: async function () {
        const results = await get('backoffice/disconnect')
        return results
    },
    isLoggedIn: async function () {
        const results = await get('api/backoffice/authenticated')
        return results
    },
    getCsrfToken: async function () {
        const results = await get('sanctum/csrf-cookie')
        return results
    },
    /**
     * @param {string} from
     */
    login: async function (email, password) {
        const results = await post('backoffice/login', {email: email, password: password})
        return results
    },
    /**
     * @param {string} from
     */
    getEvents: async function (from) {
        const results = await get('api/events', {from: from})
        return results
    },
    /**
     * @param {string} title
     * @param {string} description
     * @param {string} from
     * @param {string} to
     * @param {boolean} wholeDay
     * @param {[number]} groups
     * @param {[string]} places
     * @param {boolean} notify
     */
    createEvent: async function (title, description, from, to, wholeDay, groups, places, notify) {
        const results = await post('api/events', {title: title, description: description, from: from, to: to, wholeDay: wholeDay, groups: groups, places: places, notify: notify})
        return results
    },
    /**
     * @param {number} eventId
     * @param {string} title
     * @param {string} description
     * @param {number} from
     * @param {number} to
     * @param {boolean} wholeDay
     * @param {[number]} groups
     * @param {[string]} places
     * @param {boolean} notify
     */
    updateEvent: async function (eventId, title, description, from, to, wholeDay, groups, places, notify) {
        const results = await put('api/events/' + eventId, {title: title, description: description, from: from, to: to, wholeDay: wholeDay, groups: groups, places: places, notify: notify})
        return results
    },
    /**
     * @param {number} eventId
     * @param {boolean} notify
     */
    deleteEvent: async function (eventId, notify) {
        const results = await del('api/events/' + eventId, {notify: notify})
        return results
    },
    getGroups: async function () {
        const results = await get('api/groups')
        return results
    },
    /**
     * @param {string} groupName
     * @param {boolean} publicGroup
     */
    createGroup: async function (name, publicGroup) {
        const results = await post('api/groups', {name: name, public: publicGroup})
        return results
    },
    /**
     * @param {number} groupId
     * @param {string} name
     * @param {boolean} publicGroup
     */
    updateGroup: async function (groupId, name, publicGroup) {
        const results = await put('api/groups/' + groupId, {name: name, public: publicGroup})
        return results
    },
    /**
     * @param {number} groupId
     */
    deleteGroup: async function (groupId) {
        const results = await del('api/groups/' + groupId)
        return results
    },
    getPlaces: async function () {
        const results = await get('api/places')
        return results
    },
    /**
     * @param {string} placeName
     */
    createPlace: async function (name) {
        const results = await post('api/places', {name: name})
        return results
    },
    /**
     * @param {number} placeId
     * @param {string} placeName
     */
    updatePlace: async function (placeId, name) {
        const results = await put('api/places/' + placeId, {name: name})
        return results
    },
    /**
     * @param {number} eventId
     */
    deletePlace: async function (placeId) {
        const results = await del('api/places/' + placeId)
        return results
    },
    /**
     * @param {boolean} withChilden
     */
    getParentGroups: async function (withChilden) {
        let route = 'api/parent_groups'
        if (withChilden) {
            route += '?with=groups'
        }
        const results = await get(route)
        return results
    },
    getParentGroup: async function (parentGroupId) {
        const results = await get('api/parent_groups/' + parentGroupId)
        return results
    },
    orderParentGroups: async function (groups) {
        const results = await post('api/parent_groups/order', {parent_groups: groups})
        return results
    },
    /**
     * @param {string} parentGroupName
     */
    createParentGroup: async function (name) {
        const results = await post('api/parent_groups', {name: name})
        return results
    },
    /**
     * @param {number} parentGroupId
     */
    deleteParentGroup: async function (parentGroupId) {
        const results = await del('api/parent_groups/' + parentGroupId)
        return results
    },
    /**
     * @param {number} parentGroupId
     * @param {string} name
     * @param {[number]} groups
     */
    updateParentGroup: async function (parentGroupId, name, groups) {
        const results = await put('api/parent_groups/' + parentGroupId, {name: name, groups: groups})
        return results
    },
    orderChildrenGroups: async function (parentGroupId, groups) {
        const results = await post('api/parent_groups/' + parentGroupId + '/groups/order', {groups: groups})
        return results
    },
    orderGroups: async function (groups) {
        const results = await post('api/groups/order', {groups: groups})
        return results
    },
    getUsers: async function () {
        const results = await get('api/users')
        return results
    },
    /**
     * @param {string} email
     * @param {string} lastname
     * @param {boolean} backofficeAccess
     * @param {array} parentGroups
     */
    createUser: async function (email, lastname, backofficeAccess, parentGroups) {
        const results = await post('api/users', {email: email, lastname: lastname, backofficeAccess: backofficeAccess, parentGroups: parentGroups})
        return results
    },
    /**
     * @param {number} userId
     * @param {string} email
     * @param {string} lastname
     * @param {boolean} backofficeAccess
     * @param {array} parentGroups
     */
    updateUser: async function (userId, email, lastname, backofficeAccess, parentGroups) {
        const results = await put('api/users/' + userId, {email: email, lastname: lastname, backofficeAccess: backofficeAccess, parentGroups: parentGroups})
        return results
    },
    /**
     * @param {number} userId
     */
    deleteUser: async function (userId) {
        const results = await del('api/users/' + userId)
        return results
    },
    /**
     * @param {number} userId
     */
    getUser: async function (userId) {
        const results = await get('api/users/' + userId)
        return results
    },
}

async function get(url, params) {
    try {
        const results = await API.get(url, {params: params})
        return results.data
    } catch (error) {
        await handleError(url, error)
    }
}

async function post(url, data) {
    try {
        await API.post(url, data)
    } catch (error) {
        await handleError(url, error)
    }
}

async function del(url, params) {
    try {
        await API.delete(url, {params: params})
    } catch (error) {
        await handleError(url, error)
    }
}

async function put(url, data) {
    try {
        await API.put(url, data)
    } catch (error) {
        await handleError(url, error)
    }
}

async function handleError(url, error) {
    if (error.response) {
        if (error.response.data.errors.includes("CSRF token mismatch.")) {
            window.location = "/disconnect"
        } else if (error.response.data.errors.includes("token.invalid") && !url.includes("authenticated")) {
            window.location = "/disconnect"
        }
        throw new ApiError(error.response.data.errors, error.response.status)
    } else {
        throw error
    }
}

export default APIFetcher
