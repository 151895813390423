import React, {Component} from 'react';
import APIFetcher from "../../API";
import EventList from "./EventList";
import {Link} from "react-router-dom";

class EventsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {loading: true, events: [], error: null};
    }

    componentDidMount() {
        function toTimestamp(strDate) {
            let datum = Date.parse(strDate);
            return datum / 1000;
        }
        let currentDate = new Date();
        APIFetcher.getEvents(toTimestamp(currentDate))
            .then(response => {
                this.setState({loading: false, events: response.data, error: null})
            })
            .catch(error => {
                this.setState({loading: false, events: [], error: error})
            })
    }


    render() {
        let content;
        if (this.state.loading) {
            content = <p>loading</p>
        } else if (this.state.error) {
            content = <p>error</p>
        } else {
            content = <EventList events={this.state.events}/>
        }

        return <div className="container-sm">
            <div className="p-3">
                <p className="text-center h2">Liste des événements</p>
                <ul className="nav justify-content-end">
                    <li className="nav-item">
                        <Link className="nav-link active" to="/events/new">Créer un événement</Link>
                    </li>
                </ul>
                {content}
            </div>
        </div>
    }
}

export default EventsPage;
