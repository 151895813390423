import React, {Component} from 'react';
import APIFetcher from "../API";

class LoginPage extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        APIFetcher.disconnect().then(() => {

        }).catch(() => {
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        let form = e.target;
        APIFetcher.getCsrfToken().then(response => {
            APIFetcher.login(form.email.value, form.password.value)
                .then(response => {
                    this.props.onLogin();
                })
                .catch(error => {
                    alert(error);
                });
        });
    }

    render() {
        return <div className="container-sm">
            <div className="p-3">
                <p className="text-center h2">Se connecter</p>
                <form className="" onSubmit={this.handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input type="email" className="form-control" id="email" name="email"/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Mot de passe</label>
                        <input type="password" className="form-control" id="password" name="password"/>
                    </div>
                    <button type="submit" className="btn btn-primary">Connexion</button>
                </form>
            </div>
        </div>
    }
}

export default LoginPage;
