import React, {Component} from 'react';
import APIFetcher from "../../../API";
import {SortableContainer, SortableElement} from "react-sortable-hoc";

class OrderGroupsPage extends Component {
    constructor(props) {
        super(props);
        this.parentGroupId = this.props.match.params.parentGroupId;
        this.state = {loading: true, groups: [], error: null};
    }

    componentDidMount() {
        APIFetcher.getParentGroup(this.parentGroupId)
            .then((response) => {
                this.setState({loading: false, groups: response.data.groups, error: null});
            })
            .catch((error) => {
                this.setState({loading: false, groups: [], error: error});
            })
    }


    render() {
        let content;
        if (this.state.error) {
            content = <p>error</p>
        } else {
            let arrayMove = (arr, old_index, new_index) => {
                if (new_index >= arr.length) {
                    let k = new_index - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            }

            let onSortEnd = ({oldIndex, newIndex}) => {
                let newGroups = this.state.groups;
                arrayMove(newGroups, oldIndex, newIndex);
                this.setState({loading: false, groups: newGroups, error: this.state.error})
                let childrenGroupIds = newGroups.map(group => group.id);
                APIFetcher.orderChildrenGroups(this.parentGroupId, childrenGroupIds)
                    .then(response => {

                    })
                    .catch(error => {

                    })
            };

            const SortableItem = SortableElement(({value}) => {
                return <li className="list-group-item list-group-item-action">{value.name}</li>
            });

            const SortableList = SortableContainer(({items}) => {
                return (
                    <ul className="list-group shadow-sm mb-5 bg-body rounded">
                        {items.map((value, index) => (
                            <SortableItem key={value.id} index={index} value={value}/>
                        ))}
                    </ul>
                );
            });

            content = <SortableList items={this.state.groups} onSortEnd={onSortEnd}/>
        }

        return <div className="container-sm">
            <div className="p-3">
                <p className="text-center h2">Ordre des groupes</p>
                <nav className="navbar">
                    <div className="container-fluid">
                        <div className="fw-light">Glisser / déposer (maintenir) pour changer l'ordre</div>
                    </div>
                </nav>
                {content}
            </div>
        </div>
    }
}

export default OrderGroupsPage;
