import React, {Component} from 'react';
import {Link} from "react-router-dom";

class GroupList extends Component {
    render() {
        let groups = this.props.groups;
        let groupList = [];
        let noLink = this.props.noLink;

        if (this.props.selectedGroups) {
            let onGroupSelected = this.props.onGroupSelected || (() => {
            });
            groupList = groups.map((group) => {
                    let selected = this.props.selectedGroups.includes(group.id) ? <i className="float-end bi-check"></i> : <i></i>
                    return <li key={group.id} className="list-group-item list-group-item-action" onClick={(event) => {
                        event.stopPropagation();
                        onGroupSelected(group)
                    }}>{group.name}{selected}</li>
                }
            );
        } else {
            groupList = groups.map((group) => {
                    if (noLink) {
                        return <li key={group.id} className="list-group-item list-group-item-action">{group.name}</li>
                    } else {
                        return <Link key={group.id} className="list-group-item list-group-item-action"
                                     to={{
                                         pathname: "/groups/" + group.id + "/edit",
                                         state: group
                                     }}>{group.name}</Link>
                    }

                }
            );
        }

        return <ul className="list-group shadow-sm mb-5 bg-body rounded">
            {groupList}
        </ul>
    }
}

export default GroupList;
