import React, {Component} from 'react';
import APIFetcher from "../../../API";
import DeleteModal from "../../events/DeleteModal";
import GroupList from "../GroupList";

class EditParentGroupPage extends Component {

    constructor(props) {
        super(props);
        this.parentGroup = this.props.location.state;
        this.state = {groupsHidden: true, loading: true, groups: [], selectedGroups: [], error: null};

        this.handleDelete = this.handleDelete.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.didSelectGroup = this.didSelectGroup.bind(this);
    }

    componentDidMount() {
        APIFetcher.getGroups()
            .then(response => {
                this.setState({...this.state, groupsHidden: true, loading: false, groups: response.data, error: null})
            })
            .catch(error => {
                this.setState({...this.state, groupsHidden: true, loading: false, groups: [], error: error})
            })
        APIFetcher.getParentGroup(this.parentGroup.id)
            .then(response => {
                let selectedGroups = response.data.groups.map((group) => {
                    return group.id
                })
                this.setState({...this.state, selectedGroups: selectedGroups, error: null})
            })
            .catch(error => {
                this.setState({...this.state, groupsHidden: true, loading: false, groups: [], error: error})
            })
    }

    handleSubmit(e) {
        e.preventDefault();
        let form = e.target;
        APIFetcher.updateParentGroup(this.parentGroup.id, form.parentGroupName.value, this.state.selectedGroups)
            .then(response => {
                if (this.orderGroups) {
                    this.props.history.push("/parent_groups/" + this.parentGroup.id + "/groups/order")
                } else {
                    this.props.history.push("/parent_groups")
                }
            })
            .catch(error => {
                alert(error);
            })
    }

    handleDelete() {
        APIFetcher.deleteParentGroup(this.parentGroup.id)
            .then(response => {
                this.props.history.push("/parent_groups")
            })
            .catch(error => {
                alert(error);
            })
    }

    didSelectGroup(group) {
        let selectedGroups = this.state.selectedGroups;
        if (selectedGroups.includes(group.id)) {
            selectedGroups = selectedGroups.filter(groupId => groupId !== group.id);
        } else {
            selectedGroups.push(group.id);
        }
        this.setState({...this.state, selectedGroups: selectedGroups})
    }

    render() {
        let groupList;
        if (this.state.loading) {
            groupList = <p>loading</p>
        } else if (this.state.error) {
            groupList = <p>error loading group list</p>
        } else {
            groupList = <GroupList groups={this.state.groups} selectedGroups={this.state.selectedGroups} onGroupSelected={this.didSelectGroup}/>
        }

        return <div className="container-sm">
            <DeleteModal confirmCallback={this.handleDelete}/>
            <div className="p-3">
                <p className="text-center h2">Modifier groupe parent</p>
                <form className="shadow-sm mb-5 bg-body rounded" onSubmit={this.handleSubmit} ref={this.form}>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <label htmlFor="eventTitle" className="form-label">Nom:</label>
                            <input type="text" id="eventTitle" name="parentGroupName" className="form-control" defaultValue={this.parentGroup.name}/>
                        </li>
                        <li className="list-group-item">
                            <div className="" onClick={() => this.setState({...this.state, groupsHidden: !this.state.groupsHidden})}>
                                Groupes <i className={"float-end bi bi-chevron-" + (this.state.groupsHidden ? "right" : "down")}></i>
                            </div>
                            {this.state.groupsHidden ? <span></span> : groupList}
                        </li>
                        <li className="list-group-item text-center">
                            <div className="float-end">
                                <button type="button" className="btn btn-danger me-2" data-bs-toggle="modal" data-bs-target="#deleteModal"><i className="bi bi-trash-fill me-2"></i>Supprimer</button>
                                <button type="submit" onClick={() => {
                                    this.orderGroups = true
                                }} className="btn btn-outline-secondary me-2">Modifier ordre sous-groupes
                                </button>
                                <button type="submit" onClick={() => {
                                    this.orderGroups = false
                                }} className="btn btn-primary me-2">Modifier
                                </button>
                            </div>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    }
}

export default EditParentGroupPage;
