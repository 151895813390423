import React, {Component} from 'react';

class DisconnectPage extends Component {

    componentDidMount() {
        this.props.onDisconnect();
    }

    render() {
        return <div></div>
    }
}

export default DisconnectPage;
