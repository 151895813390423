import React, {Component} from 'react';
import {Link} from "react-router-dom";

class UserList extends Component {
    render() {
        let users = this.props.users;
        const userList = users.map((user) => {
                return <Link key={user.id} className="list-group-item list-group-item-action"
                             to={{
                                 pathname: "/users/" + user.id + "/edit",
                                 state: user
                             }}>
                    {user.lastname} - {user.email}
                    <span className="float-end">{user.userExists ? <div className="text-success">Enregistré</div>: <div className="text-secondary">Non enregistré</div>}</span>
                </Link>
            }
        );

        return <ul className="list-group shadow-sm mb-5 bg-body rounded">
            {userList}
        </ul>
    }
}

export default UserList;
