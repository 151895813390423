import React, { Component } from 'react';
import APIFetcher from "../../API";
import {Link} from "react-router-dom";
import PlaceList from "./PlaceList";

class PlacesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {loading: true, places: [], error: null};
    }

    componentDidMount() {
        APIFetcher.getPlaces()
            .then(response => {
                this.setState({loading: false, places: response.data, error: null})
            })
            .catch(error => {
                if(error.status === 401) {
                    this.props.history.push('/disconnect');
                } else {
                    this.setState({loading: false, places: [], error: error})
                }
            })
    }


    render() {
        let content;
        if (this.state.loading) {
            content = <p>loading</p>
        } else if (this.state.error) {
            content = <p>error</p>
        } else {
            content = <PlaceList places={this.state.places}/>
        }

        return <div className="container-sm">
            <div className="p-3">
                <p className="text-center h2">Liste des lieux</p>
                <ul className="nav justify-content-end">
                    <li className="nav-item">
                        <Link className="nav-link active" to="/places/new">Créer un lieu</Link>
                    </li>
                </ul>
                {content}
            </div>
        </div>
    }
}

export default PlacesPage;
