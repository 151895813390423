import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {DateTime} from "luxon";

class EventList extends Component {
    render() {
        let dateFormattingOptionsHour = {hour: 'numeric', minute: 'numeric'};
        let dateFormattingOptions = {month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};
        let dateFormattingOptionsWholeDay = {month: 'long', day: 'numeric'};

        const eventList = this.props.events.map((event) => {
                const fromDate = DateTime.fromSeconds(event.from);
                const toDate = DateTime.fromSeconds(event.to);
                let fromDateFormatted = "";
                let toDateFormatted = "";
                if (event.wholeDay) {
                    fromDateFormatted = fromDate.toLocaleString(dateFormattingOptionsWholeDay)
                    toDateFormatted = " - Jour entier";
                } else {
                    fromDateFormatted = fromDate.toLocaleString(dateFormattingOptions)
                    if (toDate.day === fromDate.day && toDate.month === fromDate.month && toDate.year === fromDate.year) {
                        toDateFormatted = " → " + toDate.toLocaleString(dateFormattingOptionsHour)
                    } else {
                        toDateFormatted = " → " + toDate.toLocaleString(dateFormattingOptions)
                    }
                }

                return <Link key={event.id} className="list-group-item list-group-item-action"
                             to={{
                                 pathname: "/events/" + event.id + "/edit",
                                 state: event
                             }}>{fromDateFormatted}{toDateFormatted} - <b>{event.title}</b></Link>
            }
        );

        return <ul className="list-group shadow-sm mb-5 bg-body rounded">
            {eventList}
        </ul>
    }
}

export default EventList;
