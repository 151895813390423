import './App.css';
import NewEventPage from "./pages/events/NewEventPage";
import EventsPage from "./pages/events/EventsPage";
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from "react-router-dom";
import ParentGroupsPage from "./pages/groups/parentgroups/ParentGroupsPage";
import NewGroupPage from "./pages/groups/NewGroupPage";
import PlacesPage from "./pages/places/PlacesPage";
import NewPlacePage from "./pages/places/NewPlacePage";
import EditEventPage from "./pages/events/EditEventPage";
import EditPlacePage from "./pages/places/EditPlacePage";
import EditGroupPage from "./pages/groups/EditGroupPage";
import NewParentGroupPage from "./pages/groups/parentgroups/NewParentGroupPage";
import GroupsPage from "./pages/groups/GroupsPage";
import EditParentGroupPage from "./pages/groups/parentgroups/EditParentGroupPage";
import OrderGroupsPage from "./pages/groups/parentgroups/OrderGroupsPage";
import UsersPage from "./pages/users/UsersPage";
import NewUserPage from "./pages/users/NewUserPage";
import EditUserPage from "./pages/users/EditUserPage";
import LoginPage from "./pages/LoginPage";
import {Component} from "react";
import APIFetcher from "./API";
import DisconnectPage from "./pages/DisconnectPage";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loggedIn: false,
        };
    }

    componentDidMount() {
        APIFetcher.isLoggedIn()
            .then(response => {
                this.setState({
                    loading: false,
                    loggedIn: true,
                });
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    loggedIn: false,
                });
            });
    }


    render() {
        let onLogin = () => {
            this.setState({
                loading: false,
                loggedIn: true,
            });
        };

        let onDisconnect = () => {
            this.setState({
                loading: false,
                loggedIn: false,
            });
        };

        if (this.state.loading) {
            return <div>Chargement...</div>;
        }

        if (this.state.loggedIn) {
            return (
                <Router>
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="/">CFPArts Backoffice</a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
                                    aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                                <div className="navbar-nav me-auto">
                                    <Link className="nav-link" to={"/groups"}>Groupes</Link>
                                    <Link className="nav-link" to={"/parent_groups"}>Groupes parents</Link>
                                    <Link className="nav-link" to={"/events"}>Événements</Link>
                                    <Link className="nav-link" to={"/places"}>Lieux</Link>
                                    <Link className="nav-link" to={"/users"}>Utilisateurs</Link>
                                </div>
                                <Link className="nav-link link-danger" to={"/disconnect"}>Déconnexion</Link>
                            </div>
                        </div>
                    </nav>
                    <Switch>
                        <Route path="/disconnect" render={() => <DisconnectPage onDisconnect={onDisconnect}/>}/>
                        <Route path="/parent_groups/new" component={NewParentGroupPage}/>
                        <Route path="/parent_groups/:parentGroupId/edit" component={EditParentGroupPage}/>
                        <Route path="/parent_groups/:parentGroupId/groups/order" component={OrderGroupsPage}/>
                        <Route path="/parent_groups" component={ParentGroupsPage}/>
                        <Route path="/groups/new" component={NewGroupPage}/>
                        <Route path="/groups/:groupId/edit" component={EditGroupPage}/>
                        <Route path="/groups" component={GroupsPage}/>
                        <Route path="/places/new" component={NewPlacePage}/>
                        <Route path="/places/:placeId/edit" component={EditPlacePage}/>
                        <Route path="/places" component={PlacesPage}/>
                        <Route path="/events/new" component={NewEventPage}/>
                        <Route path="/events/:eventId/edit" component={EditEventPage}/>
                        <Route path="/events" component={EventsPage}/>
                        <Route path="/users/new" component={NewUserPage}/>
                        <Route path="/users/:userId/edit" component={EditUserPage}/>
                        <Route path="/users" component={UsersPage}/>
                        <Route path="/">
                            <Redirect to={"/events"}/>
                        </Route>
                    </Switch>
                </Router>
            );
        } else {
            return (
                <Router>
                    <Switch>
                        <Route path="/login" render={() => <LoginPage onLogin={onLogin}/>}/>
                        <Route path="/" render={() => <Redirect to={"/login"}/>}/>
                    </Switch>
                </Router>
            );
        }
    }
}

export default App;
