import React, { Component } from 'react';
import APIFetcher from "../../API";
import {Link} from "react-router-dom";
import UserList from "./UserList";

class UsersPage extends Component {
    constructor(props) {
        super(props);

        this.state = {loading: true, users: [], error: null};
    }

    componentDidMount() {
        APIFetcher.getUsers()
            .then(response => {
                this.setState({loading: false, users: response.data, error: null})
            })
            .catch(error => {
                this.setState({loading: false, users: [], error: error})
            })
    }


    render() {
        let content;
        if (this.state.loading) {
            content = <p>loading</p>
        } else if (this.state.error) {
            content = <p>error</p>
        } else {
            content = <UserList users={this.state.users}/>
        }

        return <div className="container-sm">
            <div className="p-3">
                <p className="text-center h2">Liste des utilisateurs</p>
                <ul className="nav justify-content-end">
                    <li className="nav-item">
                        <Link className="nav-link active" to="/users/new">Ajouter un utilisateur</Link>
                    </li>
                </ul>
                {content}
            </div>
        </div>
    }
}

export default UsersPage;
