import React, {Component} from 'react';
import APIFetcher from "../../API";
import DeleteModal from "../events/DeleteModal";

class EditPlacePage extends Component {

    constructor(props) {
        super(props);
        this.place = this.props.location.state;

        this.handleDelete = this.handleDelete.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        let form = e.target;
        APIFetcher.updatePlace(this.place.id, form.placeName.value)
            .then(response => {
                this.props.history.push("/places")
            })
            .catch(error => {
                alert(error);
            })
    }

    handleDelete() {
        APIFetcher.deletePlace(this.place.id)
            .then(response => {
                this.props.history.push("/places")
            })
            .catch(error => {
                alert(error);
            })
    }

    render() {
        return <div className="container-sm">
            <DeleteModal confirmCallback={this.handleDelete}/>
            <div className="p-3">
                <p className="text-center h2">Modifier lieu</p>
                <form className="shadow-sm mb-5 bg-body rounded" onSubmit={this.handleSubmit}>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <label htmlFor="eventTitle" className="form-label">Nom:</label>
                            <input type="text" id="eventTitle" name="placeName" className="form-control" defaultValue={this.place.name}/>
                        </li>
                        <li className="list-group-item text-center">
                            <div className="float-end">
                                <button type="button" className="btn btn-danger me-2" data-bs-toggle="modal" data-bs-target="#deleteModal"><i className="bi bi-trash-fill me-2"></i>Supprimer</button>
                                <button type="submit" className="btn btn-primary me-2">Modifier</button>
                            </div>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    }
}

export default EditPlacePage;
