import React, {Component} from 'react';
import {Link} from "react-router-dom";

class PlaceList extends Component {
    render() {
        let places = this.props.places;
        const placeList = places.map((place) => {
                return <Link key={place.id} className="list-group-item list-group-item-action"
                             to={{
                                 pathname: "/places/" + place.id + "/edit",
                                 state: place
                             }}>{place.name}</Link>
            }
        );

        return <ul className="list-group shadow-sm mb-5 bg-body rounded">
            {placeList}
        </ul>
    }
}

export default PlaceList;
