import React, {Component} from 'react';
import {Link} from "react-router-dom";
import GroupList from "./GroupList";

class OrderedGroupList extends Component {
    constructor(props) {
        super(props);
        this.handleClickOnParent = this.handleClickOnParent.bind(this);
        this.handleSelectAllGroups = this.handleSelectAllGroups.bind(this);

        this.state = {deployedParentGroups: []}
    }

    handleClickOnParent(group) {
        let deployedParentGroups = this.state.deployedParentGroups;
        if (deployedParentGroups.includes(group.id)) {
            deployedParentGroups = deployedParentGroups.filter(groupId => groupId !== group.id);
        } else {
            deployedParentGroups.push(group.id);
        }
        this.setState({...this.state, deployedParentGroups: deployedParentGroups})
    }

    handleSelectAllGroups(event, parentGroup) {
        event.stopPropagation();
        let deployedParentGroups = this.state.deployedParentGroups;
        if (!deployedParentGroups.includes(parentGroup.id)) {
            deployedParentGroups.push(parentGroup.id);
        }
        let onGroupSelected = this.props.onGroupSelected || (() => {
        });
        parentGroup.groups.forEach(group => {
            onGroupSelected(group);
        });
        this.setState({...this.state, deployedParentGroups: deployedParentGroups})
    }

    render() {
        let getListClassNameForParentGroup = (parentGroup) => {
            let baseClassName = "list-group-item list-group-item-action"
            if (this.state.deployedParentGroups.includes(parentGroup.id)) {
                baseClassName += '-no-hover'
            }
            return baseClassName
        }

        let parentGroups = this.props.parentGroups;
        let parentGroupList = [];

        if (this.props.selectedGroups) {
            let onGroupSelected = this.props.onGroupSelected || (() => {
            });
            parentGroupList = parentGroups.map((parentGroup) => {
                    return <li key={parentGroup.id} className={getListClassNameForParentGroup(parentGroup)}
                               onClick={() => this.handleClickOnParent(parentGroup)}>
                        {parentGroup.name}
                        <div className="float-end">
                            <button type="button" className="btn btn-outline-primary btn-sm me-4" onClick={(event) => this.handleSelectAllGroups(event, parentGroup)}>Tout sélectionner</button>
                            <i className={"bi bi-chevron-" + (!this.state.deployedParentGroups.includes(parentGroup.id) ? "right" : "down")}></i>
                        </div>
                        <div className={"mt-3"}>
                        {this.state.deployedParentGroups.includes(parentGroup.id) ?
                            <GroupList groups={parentGroup.groups} selectedGroups={this.props.selectedGroups} onGroupSelected={onGroupSelected}/> :
                            <div></div>
                        }
                        </div>
                    </li>
                }
            );
        } else {
            parentGroupList = parentGroups.map((group) => {
                    return <Link key={group.id} className="list-group-item list-group-item-action"
                                 to={{
                                     pathname: "/groups/" + group.id + "/edit",
                                     state: group
                                 }}>{group.name}</Link>
                }
            );
        }

        return <ul className="list-group shadow-sm mb-5 bg-body rounded">
            {parentGroupList}
        </ul>
    }
}

export default OrderedGroupList;
